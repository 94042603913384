import React, { Component, Fragment } from "react";
import { Route, Switch, withRouter } from "react-router-dom";
import { SET_COOKIE, ENABLE_MEAL,IS_AUTHENTICATED } from "../Constants/AppConstants";
import requireCustAuth from "../Utils/requireCustAuth";
import Login from "../Components/Auth/Login";
import ServiceLogin from "../Components/Auth/ServiceLogin";
import PasswordReset from "../Components/Auth/PasswordReset";
import NewPasswordReset from "../Components/Auth/NewPasswordReset";

// PublicSite Start
import Home from "../Components/PublicSite/Home";
import Products from "../Components/PublicSite/Products";
import TopSellers from "../Components/PublicSite/TopSellers";
import BundleProduct from "../Components/PublicSite/BundleProduct";
import ProductDetails from "../Components/PublicSite/ProductDetails";
import CartPage from "../Components/PublicSite/CartPage";
import CheckOut from "../Components/PublicSite/CheckOut";
import OrderReceived from "../Components/PublicSite/OrderReceived";
import Athletes from "../Components/Pages/Athletes";
import GetInTouch from "../Components/Pages/GetInTouch";
import MyAccount from "../Components/PublicSite/Account/MyAccount";
import Page from "../Components/Pages/Page";
import CancellationProcess from "../Components/Pages/CancellationProcess";
import PageNotFound from "../Components/Pages/PageNotFound";
import Meals from "../Components/PublicSite/Meals";
import Plans from "../Components/PublicSite/Plans";
import AffiliateSignupRequest from "../Components/Auth/AffiliateSignupRequest";
import AffiliateSignupRequestForm from "../Components/Auth/AffiliateSignupRequestForm";
import Reorder from "../Components/PublicSite/Reorder";
import Downtime from "../Components/Pages/Downtime";
import ActivateMeal from "../Components/Pages/ActivateMeal";
import CheckOutShipping from "../Components/PublicSite/checkoutShipping"
import CheckoutPayment from "../Components/PublicSite/checkoutPayment"
import paypal_payment from "../Components/PublicSite/paypal_payment";
import CreateCustomBundle from "../Components/PublicSite/CreateCustomBundle";
import TaggedProducts from "../Components/PublicSite/TaggedProducts";
import Questionnaires from "../Components/PublicSite/Questionnaires";


class CommonRoutes extends Component {
    constructor(props) {
        super(props);

        const url = new URL(window.location.href);
        const af = url.searchParams.get("af");
        if (af) {
            SET_COOKIE("af", af);
        }
        const site = url.searchParams.get("site");
        if (site) {
            SET_COOKIE("site", site);
        }
    }

    render() {
        return (
            <Switch>
                <Route path="/login" component={Login} exact strict />
                <Route path="/serviceLogin" component={ServiceLogin} exact strict />
                <Route path="/password-reset" component={PasswordReset} exact strict />
                <Route path="/password-reset/:code" component={NewPasswordReset} exact strict />
                <Route path="/" component={Home} exact strict />
                <Route path="/products" component={Products} exact strict />
                <Route path="/quiz" component={Questionnaires} exact strict />
                <Route path="/best-sellers" component={TopSellers} exact strict />
                <Route path="/bundle-product" component={BundleProduct} exact strict />
                <Route path="/create-custom-bundle" component={CreateCustomBundle} exact strict />
                <Route path="/product/:slug" component={ProductDetails} exact strict />
                <Route path="/tagged-products/:tag_id" component={TaggedProducts} exact strict />
                <Route path="/meals" component={Plans} exact strict />
                <Route path="/meals/:id" component={Meals} exact strict />
                <Route path="/cart" component={CartPage} exact strict />
                {/* <Route path="/checkout" component={requireCustAuth(CheckOut)} exact strict /> */}
                <Route path="/checkout" component={CheckOut} exact strict />
                <Route path="/checkout-shipping" component={CheckOutShipping} exact strict />
                <Route path="/checkout-payment" component={CheckoutPayment} exact strict />
                IS_AUTHENTICATED() ?<Route path="/order-received/:order_id" component={OrderReceived} exact strict />:<Route path="/order-received/:order_id/:token" component={OrderReceived} exact strict />
                <Route path="/athletes" component={Athletes} exact strict />
                <Route path="/contact" component={GetInTouch} exact strict />
                <Route path="/reorder/:order_id" component={Reorder} exact strict />
                <Route path='/cancellation-process' component={CancellationProcess} exact strict />
                <Route path="/page/:slug" component={Page} exact strict />
                <Route path="/my-account" component={MyAccount} exact strict />
                <Route path="/my-account/" component={MyAccount} exact strict />
                <Route path="/affiliate-signup-request" component={AffiliateSignupRequest} exact strict />
                <Route path="/affiliate-signup-request-form" component={AffiliateSignupRequestForm} exact strict />
                <Route path="/error" component={Downtime} exact strict />
                <Route path="/activate-meal" component={ActivateMeal} exact strict />
                <Route path="/paypal-payment" component={paypal_payment} exact strict />
                <Route path="/paypal-payment-new/" component={paypal_payment} exact strict />
                <Route component={PageNotFound} exact strict />
            </Switch>
        );
    }
}

export default withRouter(CommonRoutes);