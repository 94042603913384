import React, { Fragment, PureComponent } from 'react';
import ReactImageFallback from "react-image-fallback";
import Parser from 'html-react-parser';
import { AJAX_PUBLIC_REQUEST, GET_STORAGE, SET_STORAGE, klaviyoAddedToCart, BASE_URL, REMOVE_STORAGE } from '../../Constants/AppConstants';
import SingleProduct from './SingleProduct';
import { NavLink } from 'react-router-dom';
import SingleProductNotAvailable from './SingleProductNotAvailable'
import SingleProductModal from './SingleProductModal';
import SingleProductNotAvailableModal from './SingleProductNotAvailableModal';
import Scrollbar from 'react-smooth-scrollbar';
import { Row, Col } from 'react-bootstrap';
// import BannerOverlay1 from './../../Assets/images/public_images/bannerText.svg'
import BannerSlider1 from './../../Assets/images/public_images/sleep_multiplier_img.svg'
import BannerBelow1 from './../../Assets/images/public_images/banner_below_image_1.svg'
import BannerBelow2 from './../../Assets/images/public_images/banner_below_image_2.svg'
import BannerBelow3 from './../../Assets/images/public_images/banner_below_image_3.svg'
import BannerBelow4 from './../../Assets/images/public_images/banner_below_image_4.svg'
import ThirdPartCertificate from './../../Assets/images/public_images/third_party_certificate_image.svg'
import ClinicalDosed from './../../Assets/images/public_images/clinical_dosed_image.svg'
import SatisfiedGuaranted from './../../Assets/images/public_images/satisfication_guar_image.svg'
import KickStartImg from './../../Assets/images/public_images/kick_start_image.svg'
import SmartSavingImg from './../../Assets/images/public_images/smart_saving_img.png'
import Productleftarrow from './../../Assets/images/public_images/bundle_left_arrow.svg'
import Productrightarrow from './../../Assets/images/public_images/bundle_right_arrow.svg'
import BannerOverlay1 from './../../Assets/images/refer-banner-image.svg'
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
class Home extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            error: '',
            topSellerShow: 3,
            // bundleShow: 3,
            ProductLink: '',
            banners: [],
            banner_image: [],
            url: '',
            filter_by: 'popular',
            pagenum: 1,
            topSellerProduct: [],
            bundleProduct: [],
            products_filters: [],
            products: [],
            productDetails: {},
            productNotAvailable: ["Bulletproof Vitality For Her", "Women's Ultimate", "Women's Immune Booster"],
            tags: [],
            taggedProducts: [],
            tagMsg: 'No products available in this category at the moment...!',
            filteredProducts: []
            // productNotAvailable:[],

        }
        document.title = "Prestige Labs";
    }

    componentDidMount() {
        document.querySelector("body").scrollIntoView();
        // document.body.classList.add('home_page');
        this.getNewBanners();
        this.getBanners();
        this.getProducts();
        this.getTags();
        this.getTaggedProducts();
        //this.getTopSellerProduct();
        //this.getBundleProduct();
      //  this.reLoadStamped();
        REMOVE_STORAGE("recommendedProducts");
    }

    // reLoadStamped = () => {
    //     setTimeout(function () { window.StampedFn.reloadUGC() }, 500);
    // };

    topSellerShow = () => {
        this.setState({
            topSellerShow: Number(this.state.topSellerShow) + 4
        })
    }

    //add to cart
    addToCart = (e) => {

        let cart = [];
        let data = [];

        if (!GET_STORAGE("cart")) {
            SET_STORAGE("cart", JSON.stringify(cart));
        }
        cart = JSON.parse(GET_STORAGE("cart"));

        let newItem = {
            cart_product_id: e.target.getAttribute('cart_product_id'),
            cart_product_name: e.target.getAttribute('cart_product_name'),
            cart_product_sku: e.target.getAttribute('cart_product_sku'),
            cart_image: e.target.getAttribute('cart_image'),
            cart_variation_id: e.target.getAttribute('cart_variation_id'),
            cart_variation_name: e.target.getAttribute('cart_variation_name'),
            cart_sale_price: e.target.getAttribute('cart_sale_price'),
            subscription: e.target.getAttribute('subscription'),
            cart_subscription_msg: e.target.getAttribute('cart_subscription_msg'),
            cart_discount_price: e.target.getAttribute('cart_discount_price'),
            quantity: parseInt(e.target.getAttribute('quantity')),
            in_stock: parseInt(e.target.getAttribute('in_stock')),
        }

        if (cart.length > 0) {
            cart.forEach(function (item, key) {
                if ((item.cart_variation_id == e.target.getAttribute('cart_variation_id')) && (item.subscription.toLowerCase() == e.target.getAttribute('subscription').toLowerCase())) {
                    if (parseInt(item.quantity) >= parseInt(e.target.getAttribute('in_stock'))) {
                        alert("Out Of Stock") // Check product quantity
                    } else {
                        item.quantity = Number(item.quantity) + Number(newItem.quantity);
                    }
                    data.push(item);
                    newItem = null;
                } else {
                    data.push(item);
                }
            });
            if (newItem != null) {
                data.push(newItem);
            }
        } else {
            data.push(newItem);
        }

        SET_STORAGE("cart", JSON.stringify(data));
        this.addToCartLabelChange();
        this.props.addToCart();
        document.querySelector("body").scrollIntoView();

        klaviyoAddedToCart(e.target.getAttribute('cart_variation_id'));
    }

    bundleShow = () => {
        this.setState({
            bundleShow: Number(this.state.bundleShow) + 3
        })
    }

    getBanners = () => {
        AJAX_PUBLIC_REQUEST("POST", "user/getBanners", {}).then(results => {
            if (parseInt(results.response.code) === 1000) {
                this.setState({
                    banners: results.response.data.sliders,
                    loading: false,
                });
            } else {
                this.setState({
                    error: Parser("<p className='text-danger'>" + results.response.message + "</p>"),
                    loading: false,
                })
            }
        });
    }

    getTags = () => {
        AJAX_PUBLIC_REQUEST("POST", "menu/tag_index", {}).then(results => {
            if (parseInt(results.response.code) === 1000) {
                this.setState({
                    tags: results.response.data.tag_data,
                    loading: false,
                    activeTab: results.response.data.tag_data[0]['id'],
                });
            } else {
                this.setState({
                    error: results.response.message,
                    loading: false,
                })
            }
        });
    }

    getNewBanners = () => {
        const data = {
            site: 'public'
        }
        AJAX_PUBLIC_REQUEST("POST", "product/getBannerData", data).then(results => {
            if (parseInt(results.response.code) === 1000) {
                this.setState({
                    banner_image: results.response.data,
                    url: results.response.data.url
                });
            } else {
                this.setState({
                    error: Parser("<p className='text-danger'>" + results.response.message + "</p>"),
                    loading: false,
                })
            }
        });
    }

    getTopSellerProduct = () => {
        const data = { type: 'single' }
        AJAX_PUBLIC_REQUEST("POST", "product/getList", data).then(results => {
            if (parseInt(results.response.code) === 1000) {
                this.setState({
                    topSellerProduct: results.response.data,
                    loading: false,
                });
            } else {
                this.setState({
                    error: Parser("<p className='text-danger'>" + results.response.message + "</p>"),
                    loading: false,
                })
            }
        });
    }


    getBundleProduct = () => {
        const data = { type: 'bundle' }
        AJAX_PUBLIC_REQUEST("POST", "product/getList", data).then(results => {
            if (parseInt(results.response.code) === 1000) {
                this.setState({
                    bundleProduct: results.response.data,
                    loading: false,
                });
            } else {
                this.setState({
                    error: Parser("<p className='text-danger'>" + results.response.message + "</p>"),
                    loading: false,
                })
            }
        });
    }
    getProducts = () => {
        const data = {
            // filter_by: filterBy,
            // pagenum: parseInt(pageNumber)
        }
        AJAX_PUBLIC_REQUEST("POST", "product/getList", data).then(results => {
            if (results.response.code === 1000) {
                this.setState({
                    products: results.response.data,
                    loading: false,
                });
            } else {
                this.setState({
                    error: Parser("<p className='text-danger'>" + results.response.message + "</p>"),
                    loading: false
                })
            }
        });
    }

    getTaggedProducts = () => {
        const data = {
            tagged_products: 'all'
        }
        AJAX_PUBLIC_REQUEST("POST", "product/getList", data).then(results => {
            if (results.response.code === 1000) {
                this.setState({
                    taggedProducts: results.response.data,
                    loading: false,
                });
                const filteredProducts = results.response.data.filter(product => parseInt(product.tag_id) == parseInt(this.state.tags[0].id));
                this.setState({ filteredProducts }, () => {
                    console.log('Tab changed, activeTab:', this.state.activeTab);
                });
            } else {
                this.setState({
                    error: Parser("<p className='text-danger'>" + results.response.message + "</p>"),
                    loading: false
                })
            }
        });
    }

    quickView = (productId) => {
        this.setState({ productDetails: {} })
        const data = {
            product_id: productId,
            site: 'refer',
        }
        AJAX_PUBLIC_REQUEST("POST", "product/getDetails", data).then(results => {
            if (parseInt(results.response.code) === 1000) {
                this.setState({
                    productDetails: results.response.data,
                });
            } else {
                this.setState({
                    error: Parser("<p className='text-danger'>" + results.response.message + "</p>"),
                })
            }
        });
    }

    notAvailablePopup = (link) => {
        this.setState({
            ProductLink: link,
        });
    }

    //owl caurosel
    options = {
        responsive: {
            0: {
                items: 1
            },
            768: {
                items: 2
            },
            1025: {
                items: 3
            },
        }
    };
    options1 = {
        responsive: {
            0: {
                items: 1
            },
            768: {
                items: 1
            },
            1025: {
                items: 1
            },
        }
    };

    handleTabClick = (tagId) => {
        this.setState({ 
            activeTab: tagId, 
            filteredProducts: []
        }, () => {
            const filteredProducts = this.state.taggedProducts.filter(product => parseInt(product.tag_id) == parseInt(this.state.activeTab));
            this.setState({ filteredProducts }, () => {
                console.log('Tab changed, activeTab:', this.state.activeTab);
            });
        });
    };

    render() {
        const quickView = this.quickView;
        const notAvailablePopup = this.notAvailablePopup;
        // const recoveryPath = "product/prestige-recovery-watermelon-formerly-intra"
        // const energyPath = "product/prestige-energy-watermelon-formerly-pre-step-1"
        // const RfullURL = BASE_URL + recoveryPath;
        // const EfullURL = BASE_URL + energyPath
        // const shopAll = "products"
        // const fullURL = BASE_URL + shopAll
        //const proteinBlend = "product/prestige-fuel-formerly-protein-blend"
        const fullURL = BASE_URL + this.state.url
        return (
            <Fragment>
                {
                    (this.state.loading) ?
                        <div className="loading container full_page_loader"></div>
                        :
                        <Fragment>
                            <div className="site-wrapper home_page">
                                {/* Banner HTML */}
                                {/* <div className="banner_section public_ban_section position-relative"> */}
                                {/* <a href = {fullURL} className='d-flex w-100'>
                                        <img src={this.state.banner_image} alt="banner overlay" class="banner_section w-100" />
                                    </a> */}

                                <div className="banner_section public_ban_section position-relative public_bundle_product">
                                    <OwlCarousel className='owl-theme' {...this.options1} dots={false} loop nav margin={0} autoplay autoplaySpeed={100} autoplayHoverPause>
                                        {this.state.banner_image.length > 0 ? (
                                            this.state.banner_image.map((banner, index) => (
                                                <div className='item' key={index}>
                                                    {/* <a href={BASE_URL + banner.url} className="d-flex w-100">
                                                        <img src={banner.large_image} alt={`Banner ${index}`} className="banner_section w-100" />
                                                    </a> */}
                                                    <a href={BASE_URL + banner.url} className="d-flex w-100" onClick={() => {
                                                        window.dataLayer = window.dataLayer || [];
                                                        window.dataLayer.push({
                                                            'event': 'gtm.linkClick', 
                                                            'banner_name': banner.large_image,
                                                            'banner_url': banner.url
                                                        });
                                                    }}>
                                                        <img src={banner.large_image} alt={`Banner ${index}`} className="banner_section w-100" />
                                                    </a>
                                                </div>
                                            ))
                                        ) : (
                                            <div className="carousel-item active">
                                                {/* <p className='text_black text-center font_16 m-0' style="padding: 20px 10px;">No banners available.</p> */}
                                            </div>
                                        )}
                                    </OwlCarousel>
                                </div>
                                {/* <a href = {fullURL} target='_blank' className="shop_energy d-block"></a> */}
                                {/* <a href={EfullURL} target='_blank' className="shop_energy d-block"></a> */}
                                {/* <div id="carouselExampleControls" class="carousel slide refer_banner_slide public_banner_slide" data-ride="carousel">
                                        <div class="carousel-inner"> */}
                                {/* <div class="carousel-item active">
                                                <a href="#" className="d-flex w-100">
                                                    <Row className='align-items-center'>
                                                        <Col md={4}>
                                                            <h1 className="public_ban_text text_white fw_inter_semi">FUEL YOUR TRANSFORMATION</h1>
                                                            <h5 className="promotes_text text_white barlow-semi-condensed-regular">Premium nutritional supplements designed to optimize performance, transform your body and unlock a healthier, happier you.</h5>
                                                            <button className='sup_all_sup_btn buy_now font_20 fw_inter_med text_white'>Shop All Supplements</button>
                                                        </Col>
                                                    </Row>
                                                </a>
                                            </div> */}
                                {/* <div class="carousel-item">
                                                <a href="#" className="d-flex w-100">
                                                    <Row className='align-items-center'>
                                                        <Col md={4}>
                                                            <h1 className="public_ban_text text_white fw_inter_semi">FUEL YOUR TRANSFORMATION1</h1>
                                                            <h5 className="promotes_text text_white barlow-semi-condensed-regular">Premium nutritional supplements designed to optimize performance, transform your body and unlock a healthier, happier you.</h5>
                                                            <button className='sup_all_sup_btn buy_now font_20 fw_inter_med text_white'>Shop All Supplements</button>
                                                        </Col>
                                                    </Row>
                                                </a>
                                            </div> */}
                                {/* <div class="carousel-item">
                                                <a href="#" className="d-flex w-100">
                                                    <Row className='align-items-center'>
                                                        <Col md={4}>
                                                            <h1 className="public_ban_text text_white fw_inter_semi">FUEL YOUR TRANSFORMATION2</h1>
                                                            <h5 className="promotes_text text_white barlow-semi-condensed-regular">Premium nutritional supplements designed to optimize performance, transform your body and unlock a healthier, happier you.</h5>
                                                            <button className='sup_all_sup_btn buy_now font_20 fw_inter_med text_white'>Shop All Supplements</button>
                                                        </Col>
                                                    </Row>
                                                </a>
                                            </div> */}
                                {/* <div class="carousel-item">
                                                <a href="/product/power-greens" className="d-flex w-100">
                                                    <Row className='align-items-center'>
                                                        <Col md={5}>
                                                            <h1 className="public_ban_text fw-my-bold">Power Greens</h1>
                                                            <h5 className="promotes_text fw_ar_reg text-uppercase">Provides Support for Healthy Blood Pressure Levels</h5>
                                                            <p className="banner_para fw_ar_reg m-0">Power Greens is a supergreens powder that provides you with key micronutrients to supercharge your immune system and help you feel your best regardless of your fitness level and goals.</p>
                                                        </Col>
                                                        <Col md={4} className="d-flex justify-content-center">
                                                            <img src={require("../../Assets/images/refer_images/shop_slider_2.png")} alt="banner slider 2" className='banner_right_img' />
                                                        </Col>
                                                        <Col md={3}>
                                                            <p className="price_text fw_ar_reg">Price</p>
                                                            <h3 className="price_amount fw-my-bold">$67</h3>
                                                            <button className="public_ban_btn fw_ar_reg d-flex align-items-center">
                                                                <span className='d-flex'>SHOP NOW</span>
                                                                <span className="ban_button_line d-flex"></span>
                                                            </button>
                                                        </Col>
                                                        <Col md={1}></Col>
                                                    </Row>
                                                </a>
                                            </div> */}
                                {/* </div>
                                    </div> */}
                                {/* </div> */}
                                {/* Banner HTML Ends */}

                                {/* Banner below HTML */}
                                <section className="banner_below">
                                    <div className="container">
                                        <Row>
                                            <Col md={4}>
                                                <div className="d-flex align-items-center flex-column">
                                                    <img src={ThirdPartCertificate} alt="third_party_certificate_image" className='mb_6' />
                                                    <h4 className='font_16 fw_inter_bold third_party_spa mb_5 text-center'>Third-Party Certified</h4>
                                                    <h6 className='font_14 barlow-semi-condensed-regular third_party_spa m-0 text-center'>For Purity & Quality</h6>
                                                </div>
                                            </Col>
                                            <Col md={4}>
                                                <div className="d-flex align-items-center flex-column">
                                                    <img src={ClinicalDosed} alt="third_party_certificate_image" className='mb_14' />
                                                    <h4 className='font_16 fw_inter_bold third_party_spa mb_5 text-center tab_nowrap'>Clinically Dosed Formulas</h4>
                                                    <h6 className='font_14 barlow-semi-condensed-regular third_party_spa m-0 text-center'>GMP Certified</h6>
                                                </div>
                                            </Col>
                                            <Col md={4}>
                                                <div className="d-flex align-items-center flex-column">
                                                    <img src={SatisfiedGuaranted} alt="third_party_certificate_image" className='mb_10' />
                                                    <h4 className='font_16 fw_inter_bold third_party_spa mb_5 text-center'>Satisfaction Guaranteed</h4>
                                                    <h6 className='font_14 barlow-semi-condensed-regular third_party_spa m-0 text-center'>30-Day No Hassle Returns</h6>
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>
                                </section>
                                {/* Banner below HTML Ends */}


                                {/* endless support HTML */}
                                <section className="endless_section_blk endless_tab_sec">
                                    <div className="container-fluid">
                                        <h2 className='text_black text-center barlow-semi-condensed-bold endless_text m-0'>Endless Support for Every Goal</h2>
                                        <p className='endless_para text_black barlow-semi-condensed-regular text-center mb_50'>Train Harder, Live Healthier, and Transform Your Physique</p>
                                        <div class="d-flex justify-content-between support_tab_blk mx-auto support_tab_btn_blk align-items-start">
                                            <div className='support_tab_blk_ul'>
                                                <Scrollbar>
                                                    <ul class="nav nav-tabs d-flex my-0 flex-nowrap" id="myTab" role="tablist">
                                                        {/* <li class="nav-item">
                                                    <a class="support_tab font_16 barlow-semi-condensed-semibold d-inline-block active" id="bestseller-tab" data-toggle="tab" href="#bestseller" role="tab" aria-controls="bestseller" aria-selected="true">BEST SELLERS</a>
                                                </li>
                                                <li class="nav-item">
                                                    <a class="support_tab font_16 barlow-semi-condensed-semibold d-inline-block" id="bundles-tab" data-toggle="tab" href="#bundles" role="tab" aria-controls="bundles" aria-selected="false">BUNDLES</a>
                                                </li>
                                                <li class="nav-item">
                                                    <a class="support_tab font_16 barlow-semi-condensed-semibold d-inline-block" id="shopall-tab" data-toggle="tab" href="#shopall" role="tab" aria-controls="shopall" aria-selected="false">SHOP ALL</a>
                                                </li> */}
                                                        {this.state.tags.map(tag => tag.body_tag == 1 && tag.tag_name != 'Build Your Own Bundle' && (
                                                            <li className="nav-item" key={tag.id}>
                                                                <a href={`#tag-${tag.id}`} className={`support_tab font_16 barlow-semi-condensed-semibold d-inline-block ${this.state.activeTab == tag.id ? 'active' : ''}`}
                                                                    id={`tag-${tag.id}-tab`} data-toggle="tab" role="tab" aria-controls={`tag-${tag.id}`} aria-selected={this.state.activeTab == tag.id ? "true" : "false"} onClick={() => this.handleTabClick(tag.id)}>
                                                                    {tag.tag_name}
                                                                </a>
                                                            </li>
                                                        )
                                                        )}
                                                    </ul>
                                                </Scrollbar>
                                            </div>

                                            {this.state.tags.some(tag => tag.tag_name == "Build Your Own Bundle") && (
                                                <a class="build_bundle create_bundle_btn font_16 fw-reg d-block text-center" href="/create-custom-bundle"> Build Your Own Bundle </a>
                                            )}

                                        </div>
                                    </div>
                                </section>
                                {/* endless support HTML Ends */}

                                {/* best Selling HTML */}
                                <section className="endless_support_tab_sec">
                                    <div className="container-fluid">
                                        <div class="tab-content" id="myTabContent">
                                            <div className={`tab-pane fade ${parseInt(this.state.activeTab) == parseInt(this.state.activeTab) ? 'show active' : ''}`}
                                                id={`tag-${this.state.activeTab}`} role="tabpanel" aria-labelledby={`tag-${this.state.activeTab}-tab`}>
                                                <div className="top_bundle_product public_bundle_product">
                                                    {
                                                        (this.state.filteredProducts.length <= 0) ? this.state.tagMsg :
                                                            <OwlCarousel className='owl-theme' {...this.options} dots={false} loop nav margin={30} autoplay autoplaySpeed={20} autoplayHoverPause>
                                                                {this.state.filteredProducts.map(function (product, key) {
                                                                    product.notAvailablePopup = this.state.productNotAvailable.includes(product.title) ? this.notAvailablePopup : "";

                                                                    // Added product quickviewer
                                                                    return (
                                                                        (this.state.productNotAvailable.includes(product.title)) ?
                                                                            <div className="item" key={Math.random()}>
                                                                                <SingleProductNotAvailable product={product} type='topSeller' />
                                                                            </div> :
                                                                            <div key={Math.random()} className="item">
                                                                                <SingleProduct key={key} product={product} type='topSeller' />
                                                                            </div>
                                                                    );
                                                                }.bind(this))
                                                                }
                                                            </OwlCarousel>
                                                    }
                                                </div>
                                            </div>
                                            {/* <div className="tab-pane fade" id="bundles" role="tabpanel" aria-labelledby="bundles-tab">
                                                <div className="top_bundle_product public_bundle_product">
                                                    {
                                                        (this.state.products.length <= 0) ? this.state.error :
                                                            <OwlCarousel className='owl-theme' {...this.options} dots={false} loop nav margin={30} autoplay autoplaySpeed={20} autoplayHoverPause>
                                                                {
                                                                    this.state.products
                                                                        .filter(product => {
                                                                            const isBundle = product.is_bundle == "1";
                                                                            return isBundle;
                                                                        })
                                                                        .map((product, key) => {
                                                                            product.notAvailablePopup = this.state.productNotAvailable.includes(product.title) ? this.notAvailablePopup : "";
                                                                            return (
                                                                                this.state.productNotAvailable.includes(product.title) ?
                                                                                    <div className="item" key={key}>
                                                                                        <SingleProductNotAvailable product={product} type='bundle' />
                                                                                    </div> :
                                                                                    <div className="item" key={key}>
                                                                                        <SingleProduct product={product} type='bundle' />
                                                                                    </div>
                                                                            );
                                                                        })
                                                                }
                                                            </OwlCarousel>
                                                    }
                                                </div>
                                            </div>
                                            <div className="tab-pane fade" id="shopall" role="tabpanel" aria-labelledby="shopall-tab">
                                                <div className="top_bundle_product public_bundle_product">
                                                    {
                                                        (this.state.products.length <= 0) ? this.state.error :
                                                            <OwlCarousel className='owl-theme' {...this.options} dots={false} loop nav margin={30} autoplay autoplaySpeed={20} autoplayHoverPause>
                                                                {
                                                                    this.state.products.map((product, key) => {
                                                                        product.notAvailablePopup = this.state.productNotAvailable.includes(product.title) ? this.notAvailablePopup : "";
                                                                        product.quickView = this.quickView;
                                                                        return (
                                                                            this.state.productNotAvailable.includes(product.title) ?
                                                                                <div key={Math.random()} className="item">
                                                                                    <SingleProductNotAvailable product={product} type='bundle' />
                                                                                </div> :
                                                                                <div key={Math.random()} className="item">
                                                                                    <SingleProduct key={key} product={product} type='bundle' />
                                                                                </div>
                                                                        );
                                                                    })
                                                                }
                                                            </OwlCarousel>
                                                    }
                                                </div>
                                            </div> */}
                                        </div>
                                        {/* <div className='bundle_heading_blk'>
                                            <h3 className="font_42 text_black fw_el_bold m-0">Best Sellers</h3>
                                            <p className="mb_34 font_20 text_black fw_ar_reg text-left best_selling_para top_selling-para">These blends often contain a combination of herbs like ginger, turmeric, burdock root, and licorice root. However, their efficacy and safety may vary, </p>
                                            <NavLink className="build_bundle create_bundle_btn font_16 fw-reg d-block text-center" to={"/create-custom-bundle"} >Build Your Own Bundle</NavLink>
                                        </div> */}


                                    </div>

                                </section>
                                {/* best Selling HTML Ends */}
                                {/* <section className="space_seperator"></section> */}
                                {/* Smart Savings,Stronger You HTML */}
                                <section className="smart_sav_section">
                                    <Row className="w-100 m-0 d-flex align-items-center">
                                        <Col md={6} className="p-0">
                                            <div className="smat_sav_blk">
                                                <h2 className="text_black barlow-semi-condensed-bold endless_text mb_50 line_hei_75px">Smart Savings, <br />Stronger You</h2>
                                                <p className="endless_para text_black barlow-semi-condensed-regular mb_70">Save even more when you shop our carefully<br /> curated bundles designed to support your specific <br />goals.</p>
                                                <a className="shop_bundle_btn buy_now font_30 barlow-semi-condensed-semibold text_white d-inline-block" href='/bundle-product'>SHOP BUNDLES</a>
                                            </div>
                                        </Col>
                                        <Col md={6} className='p-0'>
                                            <img src={SmartSavingImg} alt="smart_saving_image" className='smar_sav_img' />
                                        </Col>
                                    </Row>
                                </section>
                                {/* Smart Savings,Stronger You HTML Ends */}
                                {/* Meet our Best sellers HTML */}
                                <section className="endless_section_blk meet_our_section">
                                    <div className="container d-flex flex-column align-items-center">
                                        <h2 className='text_white text-center barlow-semi-condensed-bold endless_text mb_9 line_hei_75px'>Meet Our Best Sellers</h2>
                                        <p className='endless_para text_white barlow-semi-condensed-regular text-center mb_50'>The ultimate heath and fitness experience</p>
                                        <a className="shop_bundle_btn buy_now font_30 barlow-semi-condensed-semibold text_white d-inline-block mt_15 mb_10" href='/best-sellers'>SHOP BEST SELLERS</a>
                                    </div>
                                </section>
                                {/* <section className="endless_support_tab_sec">
                                    <div className="container-fluid">
                                        <div className="top_bundle_product">
                                            {
                                                (this.state.topSellerProduct.length <= 0) ? this.state.error :
                                                    <OwlCarousel className='owl-theme' {...this.options} dots={false} loop margin={30} autoplay autoplaySpeed={100} autoplayHoverPause>
                                                        {this.state.topSellerProduct.map(function (product, key) {
                                                            product.notAvailablePopup = this.state.productNotAvailable.includes(product.title) ? this.notAvailablePopup : "";
                                                            // Added product quickviewer
                                                            return (
                                                                (this.state.productNotAvailable.includes(product.title)) ?
                                                                    <div className="item" key={Math.random()}>
                                                                        <SingleProductNotAvailable product={product} type='topSeller' />
                                                                    </div> :
                                                                    <div key={Math.random()} className="item">
                                                                        <SingleProduct key={key} product={product} type='topSeller' />
                                                                    </div>
                                                            )
                                                        }.bind(this))
                                                        }
                                                    </OwlCarousel>
                                            }
                                        </div>
                                    </div>
                                </section>
                                <section className="space_seperator d-flex justify-content-center">
                                    <a className="shop_bundle_btn buy_now font_30 barlow-semi-condensed-semibold text_white d-inline-block mt_30 mb_10" href='/best-sellers'>SHOP BEST SELLERS</a>
                                </section> */}

                                {/* about us HTML */}
                                <section className="about_us_blk">
                                    <div className="container">
                                        <Row className="align-items-center">
                                            <Col md={5} className="">
                                                <img src={KickStartImg} alt="kick_start_image" className='w-100 kick_start_img' />
                                            </Col>
                                            <Col md={7} className="d-flex justify-content-end">
                                                <div className='w-90 w-550'>
                                                    <h2 className="text_black barlow-semi-condensed-bold endless_text mb_17 line_hei_75px">Because every hero needs a sidekick</h2>
                                                    <p className="endless_para text_black barlow-semi-condensed-regular mb_43">Your goals are our goals, and our intention is that you never stop working towards your full potential From assisting with recovery, boosting energy levels, hydrating adequately and providing immune support- we strive to help you feel and perform your best, everyday..  </p>
                                                    <a className="shop_bundle_btn buy_now font_30 barlow-semi-condensed-semibold text_white d-inline-block" href='/page/about-us'>LEARN MORE ABOUT WHO WE ARE</a>
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>
                                </section>
                                {/* about us HTML Ends */}
                                {/* <section className="space_seperator"></section> */}
                                {/* subscribe and save */}
                                <section className="subscribe_save_section">
                                    <div className="container d-flex align-items-center">
                                        <Row className="w-100 m-0">
                                            <Col md={5} className="">
                                            </Col>
                                            <Col md={7} className="">
                                                <div className=''>
                                                    <h2 className="text_white barlow-semi-condensed-bold endless_text mb_9 line_hei_75px">Subscribe and Save</h2>
                                                    <p className="endless_para text_white barlow-semi-condensed-regular mb_30">Prestige on repeat. Save  up to 15% on every order.</p>
                                                    <a className="sub_shop_now_btn font_24 barlow-semi-condensed-semibold text_black d-inline-block" href='/products'>SHOP NOW</a>
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>
                                </section>
                                {/* subscribe and save Ends */}
                                {/* <section className="space_seperator"></section> */}

                                {/* Meet our Best sellers HTML Ends */}
                                {/* unleash the power HTML */}
                                {/* <section className="top_sel_below_sec d-flex align-items-center">
                                    <div className="container d-flex align-items-center">
                                        <Row className="w-100 m-0">
                                            <Col md={6} className="p-0">
                                            </Col>
                                            <Col md={6} className="p-0">
                                                <h2 className="font_42 text_white fw_el_bold mb_34">Because every hero needs a sidekick</h2>
                                                <p className="mb_34 font_20 text_black fw_ar_reg text-left text_white">Your goals are our goals, and our intention is that you never stop working towards your full potential</p>
                                                <a className="buy_now fw_ar_reg font_16 text_black mr_36 d-inline-block" href='/page/about-us'>Who We Are</a>
                                            </Col>
                                        </Row>
                                    </div>
                                </section> */}
                                {/* unleash the power HTML Ends */}

                                {/* <!-- Bundle Product --> */}
                                {/* <section className="bundle_product public_bundle_product">
                                    <div className="container">
                                        <h3 className="font_42 text_black fw_el_bold mb_17">Bundle Products</h3>
                                        <p className="mb_34 font_20 text_black fw_ar_reg text-left best_selling_para top_selling-para">These blends often contain a combination of herbs like ginger, turmeric, burdock root, and licorice root. However, their efficacy and safety may vary, </p>
                                        <div className="top_bundle_product">
                                            <div className="mt_30 d-flex justify-content-center">
                                                <a className="view_detail d-inline-block text_white font_16 fw_ar_reg" href='/bundle-product'>View All</a>
                                            </div>
                                        </div>
                                    </div>
                                </section> */}
                                {/* <!-- EnD Bundle Product --> */}

                                {/* Carousel Stamped Reviews  */}
                                {/* <div id="stamped-reviews-widget" data-widget-type="carousel" data-title="Customer Ratings and Reviews"
                                    data-auto-play="true" data-auto-play-speed="40" data-take="15" data-style-color-title="#070708"
                                    data-style-color-star="#f5c60c" data-style-color-text="#2e1f6b" data-style-color-link="#06109e"
                                    data-random="? string:Customer Ratings and Reviews ?" data-with-photos="? string:#f5c60c ?"></div> */}
                                {/* Carousel Stamped Reviews  */}

                                {/* <section className="slider">
                                    <div id="carouselControls" className="carousel slide carousel-fade refer_slider" data-ride="carousel">
                                        <div className="carousel-inner">
                                            {
                                                (this.state.banners.length <= 0) ? this.state.error :
                                                    this.state.banners.map(function (banner, key) {
                                                        return (
                                                            <div className={key == 0 ? "carousel-item active" : "carousel-item"} key={Math.random()}>
                                                                <ReactImageFallback
                                                                    src={banner.image_url}
                                                                    fallbackImage={require('../../Assets/images/banner-loader.gif')}
                                                                    initialImage={require('../../Assets/images/banner-loader.gif')}
                                                                    alt='Banner image'
                                                                    className="d-block w-100" />
                                                            </div>
                                                        )
                                                    }.bind(this))
                                            }
                                        </div>
                                        <a className="carousel-control-prev" href="#carouselControls" role="button" data-slide="prev">
                                            <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                                            <span className="sr-only">Previous</span>
                                        </a>
                                        <a className="carousel-control-next" href="#carouselControls" role="button" data-slide="next">
                                            <span className="carousel-control-next-icon" aria-hidden="true"></span>
                                            <span className="sr-only">Next</span>
                                        </a>
                                    </div>
                                </section> */}

                                {/* <!-- Top seller --> */}
                                {/* <section className="top_seller_product">
                                    <div className="container">
                                        <div className="row top-product-list">
                                            <div className="col-md-12">
                                                <div className="title-wrapper">
                                                    <h3 className="montserrat shortcode-title">TOP SELLERS</h3>
                                                </div>
                                            </div>
                                            {
                                                (this.state.topSellerProduct.length <= 0) ? this.state.error :
                                                    this.state.topSellerProduct.map(function (product, key) {
                                                        product.notAvailablePopup = this.state.productNotAvailable.includes(product.title) ? this.notAvailablePopup : "";
                                                        // Added product quickviewer
                                                        product.quickView = this.quickView
                                                        return (
                                                            (this.state.topSellerShow <= key) ? ''
                                                                :
                                                                (this.state.productNotAvailable.includes(product.title)) ?
                                                                    <div key={Math.random()} className="col-xs-12 col-sm-4 col-md-4 col-lg-3 show_top_product product-item">
                                                                        <SingleProductNotAvailable product={product} type='topSeller' />
                                                                    </div> :
                                                                    <div key={Math.random()} className="col-xs-12 col-sm-4 col-md-4 col-lg-3 show_top_product product-item">

                                                                        <SingleProduct key={key} product={product} type='topSeller' />

                                                                    </div>
                                                        )
                                                    }.bind(this))
                                            }
                                            <div className="infinite-scrolling-homepage">
                                                {
                                                    (this.state.topSellerShow >= this.state.topSellerProduct.length) ?
                                                        <a className="refer_product_select_option disabled" id="showmore" href="javascript:void(0)">No More Product</a>
                                                        :
                                                        <a onClick={this.topSellerShow} className="refer_product_select_option" id="showmore" href="javascript:void(0)">Show More</a>
                                                }

                                            </div>
                                        </div>
                                    </div>
                                </section> */}
                                {/* <!-- EnD Top seller --> */}

                                {/* <!-- Bundle Product --> */}
                                {/* <section className="bundle_product">
                                    <div className="container">
                                        <div className="row top_bundle_product">
                                            <div className="col-md-12">
                                                <div className="title-wrapper">
                                                    <h3 className="montserrat shortcode-title">BUNDLE PRODUCTS</h3>
                                                </div>
                                            </div>
                                            {
                                                (this.state.bundleProduct.length <= 0) ? this.state.error :
                                                    this.state.bundleProduct.map(function (product, key) {
                                                        product.notAvailablePopup = this.state.productNotAvailable.includes(product.title) ? this.notAvailablePopup : "";
                                                        // Added product quickviewer
                                                        product.quickView = this.quickView
                                                        return (
                                                            (this.state.bundleShow <= key) ? ''
                                                                :
                                                                (this.state.productNotAvailable.includes(product.title)) ?
                                                                    <div key={Math.random()} className="col-xs-12 col-sm-4 col-md-6 col-lg-4 product-bundle-item">
                                                                        <SingleProductNotAvailable product={product} type='bundle' />
                                                                    </div> :
                                                                    <div key={Math.random()} className="col-xs-12 col-sm-4 col-md-6 col-lg-4 product-bundle-item">

                                                                        <SingleProduct key={key} product={product} type='bundle' />

                                                                    </div>
                                                        )
                                                    }.bind(this))
                                            }
                                            <div className="infinite-scrolling-homepage">
                                                {
                                                    (this.state.bundleShow >= this.state.bundleProduct.length) ?
                                                        <a className="refer_product_select_option disabled" id="showmorebundleproduc" href="javascript:void(0)">No More Product</a>
                                                        :
                                                        <a onClick={this.bundleShow} className="refer_product_select_option" id="showmorebundleproduc" href="javascript:void(0)">Show More</a>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </section> */}
                                {/* <!-- EnD Bundle Product --> */}
                            </div>

                            <SingleProductModal product={this.state.productDetails} />
                            <SingleProductNotAvailableModal productLink={this.state.ProductLink} />

                        </Fragment>
                }
            </Fragment>
        );
    }
}

export default Home;
